@import (reference) '~@falla/less/index';

.home {
  width: 100%;
  min-width: 1400px;
  height: 714px;
  background-image: url('./assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(204, 240, 206);
}

.wrapper {
  width: 1290px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 41px;
  padding-bottom: 54px;
  direction: ltr;

  .logo {
    img {
      width: 205px;
      height: 68px;
    }
  }

  .lang {
    display: flex;
    flex-direction: column;
    font-size: 26px;
    color: #000000;
    position: relative;
    cursor: pointer;

    .label {
      display: flex;
      align-items: center;
      span {
        //margin-right: 17px;
      }
      img {
        width: 18px;
        height: 13px;
      }
    }

    .langList {
      width: 100%;
      position: absolute;
      top: 31px;
      padding-bottom: 16px;
    }
  }
}

.content {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  position: relative;

  .left {
    padding-top: 132px;
  }

  .right {
    width: 310px;
    height: 640px;
    position: relative;
    border-radius: 48px;
    background-color: #fff;

    .swiperBg {
      width: 310px;
      height: 640px;
      position: absolute;
      z-index: 2;
      background: url('./assets/swiper_box_img.png');
      pointer-events: none;
    }

    .swiperBox {
      padding: 0 13px 0 14px;
    }

    .swiperImg {
      width: 283px;
      height: 612px;
      background-size: 100% 100%;
      border-radius: 32px;
      overflow: hidden;
      pointer-events: none;
      user-select: none;
    }

    :global {
      .adm-swiper-track {
        position: relative;
        z-index: 2;
      }
      .adm-page-indicator {
        display: flex;
        align-items: center;
      }
      .adm-page-indicator-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 9px;
      }
      .adm-page-indicator-dot-active {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: @arhbo-primary-color;
      }
    }
  }

  .title {
    font-size: 60px;
    font-weight: bold;
    color: #000000;
  }

  .subTitle {
    font-size: 32px;
    font-weight: bold;
    color: #02080c;
    letter-spacing: 2px;
  }

  .download {
    display: flex;
    margin-top: 69px;
    display: none;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 230px;
      height: 80px;
      font-size: 26px;
      color: #ffffff;
      background: #02080c;
      border-radius: 16px;
      border: 2px solid #000000;
      cursor: pointer;
      transition: @all-transition-base;

      &:last-child {
        //margin-left: 24px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.footer {
  width: 100%;
  text-align: center;
  padding-top: 91px;
  padding-bottom: 78px;
  margin: 0 auto;
  font-size: 18px;
  color: #666666;
  letter-spacing: 2px;

  .linkBtn {
    font-size: 24px;
    color: #666666;
    cursor: pointer;
    transition: @all-transition-base;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  .divide {
    display: inline-block;
    width: 2px;
    height: 15px;
    margin: 0 24px;
    background: #999999;
  }

  .copyright {
    font-size: 18px;
    color: #666666;
    letter-spacing: 2px;
    margin-top: 16px;
  }
}
