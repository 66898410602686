/**
 * index.module
 *
 * @author fukui
 */
@import (reference) '~@falla/less/index';

.dataError {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.isSmallest {
    .dataErrorCover {
      width: 135px;
      height: 105px;
      margin-bottom: 10px;
    }
  }

  &.isSmall {
    .dataErrorCover {
      width: 216px;
      height: 168px;
    }
  }

  &.isAppFullLarge {
    .dataErrorCover {
      width: 484px;
      height: 396px;
      margin-bottom: 40px;
    }
  }

  &.isSmallest,
  &.isSmall {
    .dataErrorFoot {
      font-size: 26px;
    }
  }

  &.isOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.dataErrorContainer {
  text-align: center;
}

.dataErrorCover {
  width: 270px;
  height: 210px;
  margin: 0 auto 24px;

  img {
    width: 100%;
    height: 100%;
  }
}

.dataErrorFoot {
  font-size: 30px;
  color: #c9c9c9;
  text-align: center;
}
