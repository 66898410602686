/**
 * index
 *
 * @author fukui
 */
@import './reset';

body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  font-size: 12px;
  // 解决300ms点击延迟
  touch-action: manipulation;
}

#ice-container,
.ka-wrapper,
.ka-content {
  height: 100%;
}
