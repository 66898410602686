/**
 * index.module
 *
 * @author fukui
 */
@import (reference) '~@falla/less/index';

.errorContent {
  &.isAppFullLarge {
    .refreshButton {
      margin-top: 76px;
    }
  }
}

.inlineError {
  display: flex;
  align-items: center;
  justify-content: center;
}

.refreshButton {
  margin-top: 32px;
  width: 320px;
  height: 72px;
  border-radius: 36px;
}
