/**
 * ar
 *
 * @author fukui
 */
html[lang='ar'] {
  direction: rtl;
  unicode-bidi: bidi-override;
  text-align: right;
}
