*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: auto;
}

body {
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑',
    'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body,
img,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td,
iframe {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

div,
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  display: block;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-indent: 0;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
dfn,
em,
th,
var,
i,
em {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

a,
button,
input,
select,
textarea {
  background: none;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font: 400 12px inherit;
}

input,
select,
button {
  vertical-align: middle;
  border-radius: 0;
  background-color: transparent;
}

button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: none;
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: middle;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

textarea {
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: auto;
  resize: none;
}

img {
  border: none;
  vertical-align: middle;
}

span,
i,
img {
  display: inline-block;
}

table {
  border-collapse: collapse;
}
