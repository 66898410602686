.userAgreement {
  .wrapper {
    background: #f6f7f8;
    margin: 0 auto;
    min-height: 100vh;
    padding: 20px;
    line-height: 52px;
    color: #333;

    .title {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
    }
    .content {
      font-size: 28px;
    }
  }
}

html[mobile='true'] {
  .userAgreement {
    .wrapper {
      .title {
        font-size: 16px;
        margin-bottom: 15px;
      }
      .content {
        font-size: 14px;
      }
    }

    table {
      font-size: 14px;
    }
  }
}
