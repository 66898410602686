.notFound {
  width: 100%;
  min-height: 100vh;
}

.refreshButton {
  margin-top: 60px;
  width: 320px;
  height: 72px;
  border-radius: 72px;
}
