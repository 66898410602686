.activityButton {
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translateZ(0);

  &:active:not(:disabled) {
    transform: scale(1.05);
  }

  &.isDisabled {
    filter: grayscale(1);
    cursor: not-allowed;

    &:active:not(:disabled) {
      transform: scale(1.05);
    }
  }
}
